import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import Logo from "Assets/SplashNWN.svg";
import { NatButton } from "Components";
import TextLoop from "react-text-loop";

type Props = {
  handleShowEstimator: () => void;
};

export const MainFeature: FC<Props> = ({ handleShowEstimator }) => {
  const attentionWords = ["messy", "chaotic", "dirty", "dusty", "cluttered"];
  return (
    <div
      className="flex min-h-[50vh] flex-col items-start justify-center p-8 lg:min-h-[92vh]"
      style={{
        backgroundImage: `url(${Logo})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "90% 0%",
        backgroundSize: "fit",
      }}
    >
      <div className="mb-10 rounded-lg bg-white bg-opacity-90 box-decoration-slice p-3 xl:bg-transparent">
        <div className="text-5xl font-black text-gray-700">
          Tired of a{" "}
          <TextLoop>
            {attentionWords.map((word) => (
              <span
                key={word}
                className="rounded-lg bg-primary-600 px-2 text-white"
              >
                {word}
              </span>
            ))}
          </TextLoop>{" "}
          house?
        </div>
        <div className="font-bolder mt-2 text-3xl text-gray-500">
          We can take care of that for you
        </div>
      </div>
      <NatButton
        wrapperClass="w-full md:max-w-fit"
        size="lg"
        handleClick={handleShowEstimator}
        postIcon={<FontAwesomeIcon icon={faArrowRight} />}
      >
        Get an Estimate in Seconds
      </NatButton>
    </div>
  );
};
