import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { CleaningType } from "Types";

type CleaningTypeSelectorProps = {
  type: CleaningType;
  active: boolean;
  handleSelect: (type: CleaningType) => void;
};

export const CleaningTypeSelector: FC<CleaningTypeSelectorProps> = ({
  type,
  active,
  handleSelect,
}) => {
  const getUserFriendlyType = () => {
    if (type === CleaningType.BASIC) return "Basic";
    if (type === CleaningType.CUSTOM) return "Custom";
    if (type === CleaningType.DEEP) return "Deep";
    if (type === CleaningType.UNKNOWN) return "";
  };
  const getBasePrice = () => {
    if (type === CleaningType.BASIC) return "$125";
    if (type === CleaningType.CUSTOM) return "";
    if (type === CleaningType.DEEP) return "$200";
    if (type === CleaningType.UNKNOWN) return "";
  };
  const getDescription = () => {
    if (type === CleaningType.BASIC)
      return "Give your space new life. All surface areas and visible spaces will be wiped down and dusted. Floors will be mopped and vacuumed. ";
    if (type === CleaningType.CUSTOM) return "";
    if (type === CleaningType.DEEP)
      return "Reclaim a lost space. Everything included in the basic cleaning package, plus a more detailed cleaning for areas that aren`t always visible. (Microwave, fridge, oven).";
    if (type === CleaningType.UNKNOWN) return "";
  };

  return (
    <div
      className={`m-4 rounded border-2 bg-white p-3 hover:cursor-pointer ${
        active && "border-secondary-500 bg-secondary-500"
      }`}
      onClick={() => handleSelect(type)}
    >
      <div className="flex w-full justify-between border-b-2">
        <div
          className={`pb-3 text-xl font-black ${
            active ? "text-white" : "text-gray-900"
          }`}
        >
          {getUserFriendlyType()}
          <FontAwesomeIcon
            className="ml-2 text-base opacity-30"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(`${window.location.origin}/#services`, "_blank");
            }}
            icon={faQuestionCircle}
          />
        </div>
        <div
          className={`text-xl font-black ${
            active ? "text-white" : "text-gray-900"
          }`}
        >
          <span
            className={`text-xs ${active ? "text-gray-200" : "text-gray-400"}`}
          >
            starting at
          </span>{" "}
          {getBasePrice()}
        </div>
      </div>

      <div className={`mt-3 ${active && "text-gray-200"}`}>
        {getDescription()}
      </div>
    </div>
  );
};
