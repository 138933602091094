import { addDoc, collection } from "firebase/firestore/lite";
import { SendEmail } from "./../Types/SendEmail";
import { useFirebase } from "./useFirebase";

export const useSendEmail = () => {
  const { FIREBASE_DB } = useFirebase()!;
  const MAIL_COLLECTION = collection(FIREBASE_DB, "mail");

  const send = async (data: SendEmail) => {
    try {
      const add = await addDoc(MAIL_COLLECTION, data);
      if (add) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  return { send };
};
