import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirebase } from "Hooks";
import { NatButton } from "Components";

type LocationState = {
  from?: any;
};

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { FIREBASE_AUTH } = useFirebase()!;

  const location = useLocation();
  const locationState = location.state as LocationState;
  const from = locationState?.from?.pathname || "/admin/customers";

  const handleLogin = async () => {
    const log = await signInWithEmailAndPassword(
      FIREBASE_AUTH,
      email,
      password
    );
    if (log.user) {
      navigate(from, { replace: true });
    }
  };

  return (
    <div className="box-content flex min-h-[90vh] items-center justify-center">
      <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <h2 className="text-center text-2xl font-extrabold text-secondary-500">
              Sign in to your account
            </h2>
          </div>
          <form className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-4 py-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-4 py-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                <NatButton type="link" handleClick={() => {}}>
                  Forgot your password?
                </NatButton>
              </div>
            </div>

            <div className="flex w-full justify-center">
              <NatButton handleClick={handleLogin}>Sign in</NatButton>{" "}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
