import { faSpa } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type Props = {
  text: string;
};

export const DecoratedSeparator: FC<Props> = ({ text }) => {
  return (
    <div className="relative mt-10 flex items-center">
      <div className="flex-grow border-t border-primary-500"></div>
      <div className="mx-4 flex flex-shrink items-center text-primary-500">
        <FontAwesomeIcon
          className="hidden rounded-full bg-gray-200 p-3 text-2xl text-primary-500 md:block"
          icon={faSpa}
        />
        <div className="mx-8 text-center font-serif text-5xl font-bold text-gray-500">
          {text}
        </div>
        <FontAwesomeIcon
          className="hidden rounded-full bg-gray-200 p-3 text-2xl text-primary-500 md:block"
          icon={faSpa}
        />
      </div>
      <div className="flex-grow border-t border-primary-500"></div>
    </div>
  );
};
