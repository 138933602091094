import { Content, DecoratedSeparator } from "Components";

export const NatalieFeature = () => {
  return (
    <Content wrapperClassNames="bg-white mb-10">
      <DecoratedSeparator text="Meet Your Cleaner" />

      <div className="grid grid-rows-2 items-center justify-center p-8 md:grid-rows-1">
        <div className="flex flex-wrap justify-center">
          <img
            className="aspect-square max-h-44 rounded-full border-2 object-cover shadow"
            src="https://i.imgur.com/kZMR2og.jpg"
            alt="Natalie Portrait"
          />
          <div className="mt-5 mb-5 w-full text-center text-2xl font-extrabold text-gray-700">
            Natalie Dalton
          </div>
        </div>
        <div className="text-lg leading-relaxed">
          Hello! My name is Natalie Dalton and I am the owner of Neat with Nat.
          I have loved everything involving cleaning and organizing since I was
          a kid. I love seeing a space become clutter free and functional.
          Besides cleaning, I also love to play volleyball, spending time with
          my family & friends and traveling!
        </div>
      </div>
    </Content>
  );
};
