import { FC } from "react";

type ContentProps = {
  wrapperClassNames?: string;
  innerClassNames?: string;
  id?: string;
};

export const Content: FC<ContentProps> = ({
  wrapperClassNames,
  innerClassNames,
  id,
  children,
}) => (
  <div id={id} className={`flex w-full justify-center ${wrapperClassNames}`}>
    <div className={`w-full max-w-5xl ${innerClassNames}`}>{children}</div>
  </div>
);
