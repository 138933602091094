import { Content, DecoratedSeparator } from "Components";
import { CleaningServices } from "Types";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export const ServiceFeature = () => {
  const basicServices: CleaningServices[] = [
    {
      roomName: "Kitchen",
      details: [
        "All countertops wiped",
        "All lights dusted",
        "Reachable services dusted",
        "Sinks disinfected and cleander",
        "Stovetop scrubbed and cleaned (drip bowls/burner area wiped only)",
        "Outside of oven, microwave dishwasher and refrigerator cleaned",
        "Exterior of trashcan cleaned",
        "Glass windows and glass door cleaned (only ones without blinds)",
        "Floors vacuumed and mopped",
        "Take trash out",
      ],
    },
    {
      roomName: "Bathrooms",
      details: [
        "Countertop, sink and faucet wiped down",
        "Reachable services dusted",
        "Tidy miscellaneous items",
        "Clean mirrors",
        "Toilet cleaned inside and outside. (including base and lid)",
        "Clean and dry tub, tile and shower",
        "Exterior of trashcan cleaned",
        "Take trash out",
        "Rugs shaken",
        "Floors vacuumed and mopped",
      ],
    },
    {
      roomName: "Living/Bedrooms/etc",
      details: [
        "Reachable surfaces dusted",
        "Tidy miscellaneous items",
        "Vacuum exterior of couch",
        "Beds made",
        "Glass windows and glass door cleaned (only ones without blinds)",
        "Rugs shaken",
        "Floors vacuumed and mopped",
      ],
      extra: "Laundry room is not included",
    },
  ];

  const deepServices: CleaningServices[] = [
    {
      roomName: "Kitchen",
      details: [
        "All countertops wiped",
        "All lights dusted",
        "Reachable services dusted",
        "Sinks disinfected and cleander",
        "Stovetop scrubbed and cleaned (drip bowls/burner area wiped only)",
        "Clean inside and outside of oven, microwave and refrigerator",
        "Front of dishwasher wiped",
        "Wipe down fronts of drawers and cabinets",
        "Exterior of trashcan cleaned",
        "Glass windows and glass door cleaned (only ones without blinds)",
        "Floors vacuumed and mopped",
        "Take trash out",
      ],
    },
    {
      roomName: "Bathrooms",
      details: [
        "Countertop, sink and faucet wiped",
        "Reachable services dusted",
        "Tidy miscellaneous items",
        "Clean mirrors",
        "Toilet cleaned inside and outside. (including base and lid)",
        "Clean and dry tub, tile and shower",
        "Exterior of trash cans cleaned",
        "Take trash out",
        "Rugs shaken",
        "Floors vacuumed and mopped",
      ],
    },
    {
      roomName: "Living/Bedrooms/etc",
      details: [
        "Reachable surfaces dusted",
        "Tidy miscellaneous items",
        "Vacuum exterior of couch",
        "Beds made",
        "Glass windows and glass door cleaned (only ones without blinds)",
        "Rugs shaken",
        "Floors vacuumed and mopped",
        "Baseboards wiped (only visible baseboard, no furniture will be moved)",
      ],
      extra: "Laundry room is not included",
    },
  ];

  const renderSlide = (service: CleaningServices) => (
    <SwiperSlide key={service.roomName} className="px-5 pb-5 pt-3 text-center">
      <div className="mb-3 text-2xl font-bold">{service.roomName}</div>
      <ul>
        {service.details.map((el) => (
          <li className="mb-2 text-left text-base" key={el}>
            {el}
          </li>
        ))}
      </ul>
      {service.extra && (
        <b className="text-center">
          <i>{service.extra}</i>
        </b>
      )}
    </SwiperSlide>
  );

  const serviceSection = (
    serviceName: string,
    services: CleaningServices[]
  ) => (
    <div>
      <div className="w-100 rounded-t-md bg-secondary-500 p-5 text-center text-3xl font-bold text-white">
        {serviceName}
      </div>
      <Swiper
        className="w-100 h-100 mx-5 rounded-b-md border-4 border-solid border-secondary-500 bg-white"
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination]}
      >
        {services.map(renderSlide)}
      </Swiper>
    </div>
  );

  return (
    <Content wrapperClassNames="bg-white">
      <a className="hidden" id="services" href="/">
        Services
      </a>
      <DecoratedSeparator text="Services" />
      <div className="m-5 mt-10 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
        {serviceSection("Basic Cleaning", basicServices)}
        {serviceSection("Deep Cleaning", deepServices)}
      </div>
    </Content>
  );
};
