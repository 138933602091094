import {
  faArrowLeft,
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NatButton } from "Components";
import { CustomerDetailsForm } from "Forms/CustomerDetailsForm";
import { useCustomers, useScreenSize } from "Hooks";
import { useSendEmail } from "Hooks/useSendEmail";
import { FC, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Drawer } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { CleaningType, CustomerDetails } from "Types";
import { CleaningTypeSelector } from "./CleaningTypeSelector";
import { RoomSection } from "./RoomSection";
import ReCAPTCHA from "react-google-recaptcha";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const BASE_BEDROOM_COUNT = 3;
const BASE_BATHROOM_COUNT = 2;
const BASE_COMMON_ROOM_COUNT = 2;

export const EstimateDrawer: FC<Props> = ({ open, handleClose }) => {
  const roomInfoSection = useRef<HTMLDivElement>(null);
  const userInfoSection = useRef<HTMLDivElement>(null);

  const [selectedCleaningType, setSelectedCleaningType] =
    useState<CleaningType>();

  const [bedroomCount, setBedroomCount] = useState(BASE_BEDROOM_COUNT);
  const [bathroomCount, setBathroomCount] = useState(BASE_BATHROOM_COUNT);
  const [commonRoomCount, setCommonRoomCount] = useState(
    BASE_COMMON_ROOM_COUNT
  );
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const [customPricing, setCustomPricing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [captchaValue, setCaptchaValue] = useState<string | null>();
  const [showCaptchaError, setShowCaptchaError] = useState(false);

  const { width: screenWidth } = useScreenSize();
  const { create } = useCustomers()!;
  const { send } = useSendEmail();

  const scrollToRoomInfo = () =>
    roomInfoSection?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

  useEffect(() => {
    if (
      bedroomCount !== BASE_BEDROOM_COUNT ||
      bathroomCount !== BASE_BATHROOM_COUNT ||
      commonRoomCount !== BASE_COMMON_ROOM_COUNT
    ) {
      setCustomPricing(true);
    } else {
      setCustomPricing(false);
    }
  }, [bedroomCount, bathroomCount, commonRoomCount]);

  useEffect(() => {
    if (selectedCleaningType) scrollToRoomInfo();
  }, [selectedCleaningType]);

  useEffect(() => {
    scrollToRoomInfo();
  }, [customPricing]);

  const getDrawerSize = () => {
    if (!screenWidth || screenWidth < 600) return "xs";
    if (screenWidth > 600 && screenWidth < 800) return "sm";
    if (screenWidth > 800 && screenWidth < 1000) return "md";
    else return "lg";
  };

  const handleCaptcha = (value: string | null) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  };

  return (
    <Drawer open={open} onClose={handleClose} size={getDrawerSize()}>
      <Drawer.Header className="h-[4.5rem] ">
        <div className="mt-[0.1rem] text-2xl font-bold">
          Estimate your Cleaning
        </div>
      </Drawer.Header>
      <Drawer.Body style={{ padding: "0.5rem" }} className="bg-gray-100">
        <FontAwesomeIcon
          className="my-3 rounded bg-white p-2 text-xl hover:cursor-pointer"
          icon={faArrowLeft}
          onClick={handleClose}
        />
        <div className="rounded bg-white p-4 shadow">
          <div className="text-center text-lg font-bold text-gray-800">
            First, what type of cleaning do you want?
          </div>

          <CleaningTypeSelector
            handleSelect={setSelectedCleaningType}
            active={selectedCleaningType === CleaningType.BASIC}
            type={CleaningType.BASIC}
          />
          <CleaningTypeSelector
            handleSelect={setSelectedCleaningType}
            active={selectedCleaningType === CleaningType.DEEP}
            type={CleaningType.DEEP}
          />
        </div>

        {selectedCleaningType && (
          <>
            <div
              ref={roomInfoSection}
              className="mt-10 rounded bg-white p-4 shadow"
            >
              <div className="mb-6 text-center text-lg font-bold text-gray-800">
                Next, tell us a little about what you want cleaned
              </div>

              {customPricing && (
                <div className="flex items-center space-x-2 rounded bg-yellow-200 p-4 text-lg">
                  <FontAwesomeIcon
                    className="text-3xl text-yellow-700"
                    icon={faExclamationTriangle}
                  />
                  <div className="text-md text-yellow-700">
                    Submit your information below and we will reach out to you
                    shortly to give you a custom price!
                  </div>
                </div>
              )}

              <div className="divide-y">
                <RoomSection
                  title="Bedrooms"
                  value={bedroomCount}
                  handleChange={setBedroomCount}
                />

                <RoomSection
                  title="Bathrooms"
                  value={bathroomCount}
                  handleChange={setBathroomCount}
                />

                <RoomSection
                  title="Kitchen/Living Room/Common Areas"
                  value={commonRoomCount}
                  handleChange={setCommonRoomCount}
                />
              </div>
            </div>

            <div
              ref={userInfoSection}
              className="mt-10 rounded bg-white p-4 shadow"
            >
              <div className="mb-6 text-center text-lg font-bold text-gray-800">
                Finally, tell us a little about yourself
              </div>
              <CustomerDetailsForm
                wrapperClass="space-y-3"
                handleChange={setCustomerDetails}
              />
              <div className="mt-5">
                {showCaptchaError && (
                  <div className="text-red-500">Please complete captcha</div>
                )}
                <ReCAPTCHA
                  sitekey="6LdvNnQgAAAAAFBiF75HI-v5kJ7XFeM13kFY9ubm"
                  onChange={handleCaptcha}
                />
              </div>
              <div className="flex justify-end">
                <NatButton
                  wrapperClass="w-full mt-5"
                  size="lg"
                  handleClick={async () => {
                    if (!captchaValue) {
                      setShowCaptchaError(true);
                      return;
                    }
                    setSubmitting(true);
                    const res = await create({
                      stage: "lead",
                      cleaningType: selectedCleaningType,
                      bedroomCount,
                      bathroomCount,
                      commonRoomCount,
                      ...customerDetails,
                    });
                    const mailRes = await send({
                      to: ["neatwithnat21@gmail.com", "jaseowens197@gmail.com"],
                      message: {
                        subject: `New Customer Lead - ${customerDetails?.firstName} ${customerDetails?.lastName}`,
                        html: `
                          A new lead has been created, ${customerDetails?.firstName} ${customerDetails?.lastName} has requested services.
                          <br>
                          <br>
                          Cleaning Type: ${selectedCleaningType}
                          <br>
                          Bedrooms: ${bedroomCount}
                          <br>
                          Bathrooms: ${bathroomCount}
                          <br>
                          Common Areas: ${commonRoomCount}
                          <br>
                          Click <a href="https://www.neatwithnat.com/admin/customers/${res}">here</a> for more details
                          `,
                      },
                    });
                    if (res && mailRes) {
                      toast(
                        `You're request has been recieved, look forward to us reaching out to you!`
                      );
                      setBedroomCount(BASE_BEDROOM_COUNT);
                      setBathroomCount(BASE_BATHROOM_COUNT);
                      setCommonRoomCount(BASE_COMMON_ROOM_COUNT);
                      setCustomerDetails({});
                      setSubmitting(false);
                      setSelectedCleaningType(undefined);
                      handleClose();
                    } else {
                      setSubmitting(false);
                      toast(
                        `Uh oh, something went wrong, please try again or consider reaching out on our Facebook. Sorry for the trouble!`
                      );
                    }
                  }}
                >
                  {submitting ? (
                    <FontAwesomeIcon
                      className="animate-spin"
                      icon={faSpinner}
                    />
                  ) : (
                    "Submit"
                  )}
                </NatButton>
              </div>
            </div>
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
};
