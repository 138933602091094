import { FC } from "react";

interface InputProps {
  id: string;
  autoComplete?: string;
  inputType:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  label?: string;
  placeholder: string;
  defaultValue?: string;
  inputClassNames?: string;
  handleChange: (val: string) => void;
}

export const Input: FC<InputProps> = ({
  id,
  label,
  autoComplete,
  inputType,
  defaultValue,
  inputClassNames,
  placeholder,
  handleChange,
}) => {
  return (
    <div className="w-full">
      {label && (
        <label className="text-sm font-bold" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        name={label}
        type={inputType}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        required
        className={`mt-1 w-full rounded border-0 bg-gray-100 px-4 py-4 text-lg text-gray-900 placeholder-gray-400 focus:z-10 focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 ${inputClassNames}`}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};
