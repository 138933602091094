import { CSSProperties, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import NWNLogo from "Assets/NWNBroom.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "@firebase/auth";
import { useFirebase } from "Hooks";
import { Content } from "Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Disclosure } from "@headlessui/react";
import { NatButton } from "Components";
import PubSub from "pubsub-js";

interface TopNavProps {
  wrapperStyle?: CSSProperties;
}

interface TopNavLinkProps {
  href: string;
  text: string;
  internal?: boolean;
  handleClick?: () => void;
}

const TopNavLink: FC<TopNavLinkProps> = ({
  href,
  text,
  internal = false,
  handleClick,
}) => {
  if (internal) {
    return (
      <a
        href={href}
        onClick={handleClick}
        className="mt-4 mr-4 block text-xl text-gray-400 hover:text-secondary-500 lg:mt-0 lg:inline-block lg:text-base"
      >
        {text}
      </a>
    );
  }
  return (
    <Link
      to={href}
      onClick={handleClick}
      className="mt-4 mr-4 block text-xl text-gray-400 hover:text-secondary-500 lg:mt-0 lg:inline-block lg:text-base"
    >
      {text}
    </Link>
  );
};

export const TopNav: FC<TopNavProps> = ({ wrapperStyle }) => {
  const { FIREBASE_AUTH } = useFirebase()!;
  const [user, loading] = useAuthState(FIREBASE_AUTH);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut(FIREBASE_AUTH);
    navigate("/");
  };

  return (
    <Disclosure as="nav" className="absolute z-[9999]">
      {({ open }) => (
        <>
          <Content
            wrapperClassNames="fixed top-0 left-0 w-full bg-white shadow"
            innerClassNames="items-center flex justify-between bg-white py-3 px-3"
          >
            <Link
              className="ml-3 mr-6 flex flex-shrink-0 items-center text-black"
              to="/"
            >
              {/* ICON */}
              <div className="mr-3 w-8">
                <img src={NWNLogo} alt="Logo" />
              </div>
              <div className="flex items-center text-xl font-semibold tracking-tight text-primary-500">
                NEAT{" "}
                <span className="mx-1 text-base text-secondary-500">WITH</span>{" "}
                NAT
              </div>
            </Link>
            <div className="lg:hidden">
              {/* Mobile menu button*/}
              <Disclosure.Button className="flex items-center rounded py-2 px-3 text-xl text-secondary-500 hover:bg-gray-100 hover:text-secondary-700">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <FontAwesomeIcon
                    className="block h-6 w-6"
                    aria-hidden="true"
                    icon={faTimes}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="block h-6 w-6"
                    aria-hidden="true"
                    icon={faBars}
                  />
                )}
              </Disclosure.Button>
            </div>

            <div className="hidden w-full flex-grow lg:flex lg:w-auto lg:items-center">
              <div className="text-sm lg:flex-grow">
                {/* LINKS */}
                {!user || loading ? (
                  <>
                    <TopNavLink internal href="#why" text="Why Us" />
                    <TopNavLink internal href="#services" text="Services" />
                    <TopNavLink internal href="#about" text="About Us" />
                  </>
                ) : (
                  <>
                    <TopNavLink href="/admin/customers" text="Customers" />
                    <TopNavLink href="/admin/jobs" text="Jobs" />
                    <TopNavLink href="/admin/expenses" text="Expenses" />
                  </>
                )}
              </div>
              {user ? (
                <NatButton type="secondary" handleClick={handleSignOut}>
                  Log Out
                </NatButton>
              ) : (
                <NatButton
                  type="secondary"
                  handleClick={() => PubSub.publish("TOPNAV", "")}
                >
                  Get an Estimate
                </NatButton>
              )}
            </div>
          </Content>

          <Disclosure.Panel
            as="span"
            className="fixed left-0 w-full rounded-b-xl border-b-2 bg-white shadow lg:hidden"
          >
            {({ close }) => (
              <div className="space-y-3 px-2 pb-3">
                {/* Mobile Nav pop up */}
                {!user || loading ? (
                  <>
                    <TopNavLink
                      handleClick={() => close()}
                      internal
                      href="#why"
                      text="Why Us"
                    />
                    <TopNavLink
                      handleClick={() => close()}
                      internal
                      href="#services"
                      text="Services"
                    />
                    <TopNavLink
                      handleClick={() => close()}
                      internal
                      href="#about"
                      text="About Us"
                    />
                    <NatButton
                      wrapperClass="w-full"
                      type="secondary"
                      handleClick={() => {
                        close();
                        PubSub.publish("TOPNAV", "");
                      }}
                    >
                      Get an Estimate
                    </NatButton>
                  </>
                ) : (
                  <>
                    <TopNavLink
                      handleClick={() => close()}
                      href="/admin/customers"
                      text="Customers"
                    />
                    <TopNavLink
                      handleClick={() => close()}
                      href="/admin/jobs"
                      text="Jobs"
                    />
                    <TopNavLink
                      handleClick={() => close()}
                      href="/admin/expenses"
                      text="Expenses"
                    />
                    <NatButton
                      wrapperClass="w-full"
                      type="secondary"
                      handleClick={() => {
                        handleSignOut();
                        close();
                      }}
                    >
                      Log Out
                    </NatButton>
                  </>
                )}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
