import {
  faCalendarPlus,
  faForward,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, DecoratedSeparator, Timeline } from "Components";

export const AboutUsFeature = () => {
  return (
    <Content wrapperClassNames="bg-white">
      <a className="hidden" id="about" href="/">
        About
      </a>
      <DecoratedSeparator text="About Us" />
      <p className="mx-5 my-10 text-lg leading-relaxed">
        Natalie has always had an itch for cleaning and organizing. Anyone who
        knows her knows she`s the go to for re-claiming any space that has been
        taken away by clutter, junk, life. In late 2021 Natalie Dalton decided
        to take a leap of faith and created her first business - “Neat with
        Nat”. During these first few months Natalie was still working a full
        time job, but the support received from the community and the joy from
        doing the work made it clear that this was what she was meant to be
        doing. In March of 2022 Natalie took another leap and went full time
        with Neat with Nat. And for now, this is where the story ends. But there
        are so many exciting things planned for Neat with Nat. Online booking,
        hiring employees, a blog, the list goes on. Be sure to stay tuned for
        updates on where Natalie takes Neat with Nat!
      </p>

      <Timeline
        steps={[
          {
            title: "Launch!",
            icon: <FontAwesomeIcon icon={faRocket} />,
            subtitle: "Dec, 2021",
            description: "Neat with Nat is started",
          },
          {
            title: "Full Time!",
            icon: <FontAwesomeIcon icon={faCalendarPlus} />,
            subtitle: "March, 2022",
            description: "Natalie goes full time with Neat with Nat",
          },
          {
            title: "Future!",
            icon: <FontAwesomeIcon icon={faForward} />,
            subtitle: "Present",
            description: "Growth and endless possibilities!",
          },
        ]}
      />
    </Content>
  );
};
