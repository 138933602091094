import { CustomerList, CustomerDetail, CreateCustomer } from "Pages";
import { Route, Routes } from "react-router-dom";

export const CustomerRoutes = () => {
  return (
    <Routes>
      <Route index element={<CustomerList />} />
      <Route path=":id" element={<CustomerDetail />} />
      <Route path="add" element={<CreateCustomer />} />
    </Routes>
  );
};
