import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Content, NatButton } from "Components";

interface PageTitleProps {
  title: string;
  extraButtonText?: string;
  handleExtraClick?: () => void;
  handlePreviousClick?: () => void;
}

export const PageTitle: FC<PageTitleProps> = ({
  title,
  extraButtonText,
  handleExtraClick,
  handlePreviousClick,
}) => {
  return (
    <>
      <Content wrapperClassNames="bg-white pt-1 pb-3" innerClassNames="my-5">
        <div className="flex justify-between">
          <div className="flex items-center text-4xl font-black tracking-wider text-gray-800">
            {handlePreviousClick && (
              <button
                className="mr-2 text-2xl"
                onClick={(e) => {
                  e.preventDefault();
                  handlePreviousClick();
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
            )}
            {title}
          </div>
          {extraButtonText && (
            <NatButton handleClick={handleExtraClick}>
              {extraButtonText}
            </NatButton>
          )}
        </div>
      </Content>
      <div className="border-b-2"></div>
    </>
  );
};
