import { FC } from "react";
import "./Slider.css";

type Props = {
  min: number;
  max: number;
  value: number;
  handleChange?: (val: number) => void;
  className?: string;
  id?: string;
};

export const Slider: FC<Props> = ({
  min,
  max,
  value,
  handleChange,
  id,
  className,
}) => {
  return (
    <input
      type="range"
      min={min}
      max={max}
      value={value}
      className={`slider ${className}`}
      id={id}
      onChange={(ev) => handleChange && handleChange(Number(ev.target.value))}
    />
  );
};
