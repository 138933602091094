import {
  faEarthAmerica,
  faUsers,
  faStar,
  faFireFlameCurved,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, DecoratedSeparator } from "Components";
import { FC } from "react";

export const WhyFeature = () => {
  const FeatureWrapper: FC = ({ children }) => {
    return (
      <div className="flex h-full flex-col flex-wrap justify-start rounded-lg border-2 border-gray-100 shadow hover:scale-105 hover:shadow-lg">
        {children}
      </div>
    );
  };
  const FeatureTop: FC = ({ children }) => {
    return (
      <div className="flex-shrink rounded-t-lg bg-secondary-500 p-4 text-center text-2xl text-white">
        {children}
      </div>
    );
  };
  const FeatureBottom: FC = ({ children }) => {
    return (
      <div className="flex-grow rounded-b-lg bg-gray-50 p-4 text-lg text-gray-700">
        {children}
      </div>
    );
  };
  return (
    <Content wrapperClassNames="bg-white">
      <a className="hidden" id="why" href="/">
        Why
      </a>
      <DecoratedSeparator text="Why Us" />
      <div className="m-5 mt-10 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4">
        <div>
          <FeatureWrapper>
            <FeatureTop>
              <div>
                <FontAwesomeIcon className="text-5xl" icon={faEarthAmerica} />
              </div>
              <div className="mt-2 font-black">Location</div>
            </FeatureTop>
            <FeatureBottom>
              We are conveniently located in Birmingham, AL, and are more than
              willing to travel to outside of the area to provide services.
            </FeatureBottom>
          </FeatureWrapper>
        </div>

        <div>
          <FeatureWrapper>
            <FeatureTop>
              <div>
                <FontAwesomeIcon className="text-5xl" icon={faUsers} />
              </div>
              <div className="mt-2 font-black">Locally Owned</div>
            </FeatureTop>
            <FeatureBottom>
              We are a small and growing company, we love our community and love
              helping out where we can.
            </FeatureBottom>
          </FeatureWrapper>
        </div>

        <div>
          <FeatureWrapper>
            <FeatureTop>
              <div>
                <FontAwesomeIcon className="text-5xl" icon={faStar} />
              </div>
              <div className="mt-2 font-black">Pride</div>
            </FeatureTop>
            <FeatureBottom>
              We take pride in our work, we never leave customers hanging or
              feeling disappointed.
            </FeatureBottom>
          </FeatureWrapper>
        </div>

        <div>
          <FeatureWrapper>
            <FeatureTop>
              <div>
                <FontAwesomeIcon
                  className="text-5xl"
                  icon={faFireFlameCurved}
                />
              </div>
              <div className="mt-2 font-black">Passion</div>
            </FeatureTop>
            <FeatureBottom>
              Cleaning is our passion, we love doing what we do, and that leads
              to a cleaner space for you.
            </FeatureBottom>
          </FeatureWrapper>
        </div>
      </div>
    </Content>
  );
};
