import { Outlet } from "react-router-dom";
import { Footer, TopNav } from "Components";
import { ToastContainer } from "react-toastify";

export const AppLayout = () => {
  return (
    <>
      <TopNav />
      <div className="mt-[4.5rem] box-content min-h-[90vh] bg-gray-100">
        <Outlet />
      </div>
      <Footer />
      <ToastContainer draggable={false} position="top-right" newestOnTop />
    </>
  );
};
