import { FC } from "react";
import { CustomerStage } from "Types";

type Props = {
  stage?: CustomerStage;
};

export const CustomerStageTag: FC<Props> = ({ stage }) => {
  const getColor = () => {
    if (stage === "lead") return "bg-green-500";
    if (stage === "lost") return "bg-red-500";
  };

  if (stage === "customer") return <></>;
  return (
    <div
      className={`ml-2 flex items-center rounded px-1 text-xs text-white ${getColor()}`}
    >
      {stage?.toUpperCase()}
    </div>
  );
};
