import { Slider } from "Components";
import { FC } from "react";
import { Col, InputNumber, Row } from "rsuite";

type RoomSectionProps = {
  title: string;
  handleChange: (val: number) => void;
  value: number;
};

export const RoomSection: FC<RoomSectionProps> = ({
  title,
  value,
  handleChange,
}) => {
  return (
    <div className="py-3">
      <div className="text-lg font-bold">{title}</div>
      <Row className="mx-4 mb-4 mt-1 flex items-center">
        <Col xs={19}>
          <Slider
            max={10}
            min={0}
            handleChange={handleChange}
            value={value}
            className="mr-2 w-full"
          />
        </Col>

        <Col xs={5}>
          <InputNumber
            min={0}
            max={10}
            value={value}
            onChange={(value) => {
              handleChange(Number(value));
            }}
            className="w-20"
          />
        </Col>
      </Row>
    </div>
  );
};
