import { PaymentType } from "Types";

export const PaymentTypeSelectOptions = [
  { label: "Unknown", value: PaymentType.UNKNOWN },
  { label: "Cash", value: PaymentType.CASH },
  {
    label: "Credit Card",
    value: PaymentType.CREDIT_CARD,
  },
  { label: "Venmo", value: PaymentType.VENMO },
  { label: "Check", value: PaymentType.CHECK },
];
