import { MutatingDots } from "react-loader-spinner";

export const Loading = () => {
  return (
    <div className="flex h-48 w-full items-center justify-center">
      <MutatingDots
        height="100"
        width="100"
        secondaryColor="#0E387A"
        color="#9FAFCA"
        ariaLabel="loading"
        radius={15}
      />
    </div>
  );
};
