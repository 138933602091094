import { Select } from "Components";
import { CleaningFrequencySelectOptions } from "Constants/CleaningFrequency";
import { CleaningTypeSelectOptions } from "Constants/CleaningTypes";
import { PaymentTypeSelectOptions } from "Constants/PaymentTypes";
import { FC, useEffect, useState } from "react";
import {
  CleaningFrequency,
  CleaningType,
  Customer,
  CustomerDetails,
  PaymentType,
} from "Types";
import { CustomerDetailsForm } from "./CustomerDetailsForm";

type Props = {
  initialData?: Customer;
  handleChange?: (data: Customer) => void;
};

const FormSection: FC = ({ children }) => (
  <div className="mb-5 text-3xl font-black tracking-wide text-gray-700">
    {children}
  </div>
);

export const CustomerForm: FC<Props> = ({ initialData, handleChange }) => {
  const [paymentType, setPaymentType] = useState<PaymentType>(
    initialData?.paymentType ?? PaymentType.UNKNOWN
  );
  const [cleaningType, setCleaningType] = useState<CleaningType>(
    initialData?.cleaningType ?? CleaningType.UNKNOWN
  );
  const [cleaningFrequency, setCleaningFrequency] = useState<CleaningFrequency>(
    initialData?.cleaningFrequency ?? CleaningFrequency.UNKNOWN
  );

  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();

  useEffect(() => {
    const data: Customer = {
      ...customerDetails,
      paymentType,
      cleaningType,
      cleaningFrequency,
    };
    handleChange && handleChange(data);
  }, [
    handleChange,
    customerDetails,
    paymentType,
    cleaningType,
    cleaningFrequency,
  ]);

  return (
    <form>
      <div className="space-y-4">
        <FormSection>Customer Details</FormSection>
        <CustomerDetailsForm
          wrapperClass="space-y-3 rounded-lg bg-white p-5 shadow-lg"
          initialData={
            initialData && {
              address: initialData?.address,
              city: initialData.city,
              email: initialData.email,
              phoneNumber: initialData.phoneNumber,
              firstName: initialData.firstName,
              lastName: initialData.lastName,
              name: initialData.name,
              state: initialData.state,
              zip: initialData.zip,
            }
          }
          handleChange={setCustomerDetails}
        />
        <div>
          <div className="mt-5 pt-3">
            <FormSection>Customer Preferences</FormSection>
          </div>
        </div>
        <div className="rounded-lg bg-white p-5 shadow-lg">
          <div className="flex flex-1 flex-row flex-nowrap space-x-3">
            <Select
              options={PaymentTypeSelectOptions}
              label="Payment Type"
              id="paymentType"
              name="PaymentType"
              handleChange={(val) => setPaymentType(val as PaymentType)}
              defaultValue={paymentType}
            />
            <Select
              options={CleaningTypeSelectOptions}
              label="Cleaning Type"
              id="cleaningType"
              name="CleaningType"
              handleChange={(val) => setCleaningType(val as CleaningType)}
              defaultValue={cleaningType}
            />
            <Select
              options={CleaningFrequencySelectOptions}
              label="Cleaning Frequency"
              id="cleaningFrequency"
              name="CleaningFrequency"
              handleChange={(val) =>
                setCleaningFrequency(val as CleaningFrequency)
              }
              defaultValue={cleaningFrequency}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
