import { FC, ReactNode } from "react";

type Step = {
  icon: ReactNode;
  title: string;
  subtitle: string;
  description: string;
};

type Props = {
  steps: Step[];
};

export const Timeline: FC<Props> = ({ steps }) => {
  const Step: FC<Step & { isLast: boolean }> = ({
    icon,
    title,
    subtitle,
    description,
    isLast,
  }) => {
    return (
      <li className="relative">
        <div className="flex items-center">
          <div className="flex w-full items-center justify-center">
            <div className="flex h-16 w-16 items-center justify-center rounded-full bg-secondary-500 text-2xl text-white">
              {icon}
            </div>
          </div>

          {!isLast && (
            <div className="hidden h-0.5 w-full bg-gray-200 sm:flex"></div>
          )}
        </div>
        <div className="mt-3 sm:pr-8">
          <h3 className="text-center text-lg font-semibold text-gray-700 md:text-left">
            {title}
          </h3>
          <div className="mb-2 block  text-center text-sm font-normal leading-none text-gray-400 md:text-left">
            {subtitle}
          </div>
          <p className="text-center text-base font-normal text-gray-500">
            {description}
          </p>
        </div>
      </li>
    );
  };
  return (
    <ol className="items-center space-y-4 sm:flex md:space-y-0">
      {steps.map((el, index) => (
        <Step key={el.title} isLast={index === steps.length - 1} {...el} />
      ))}
    </ol>
  );
};
