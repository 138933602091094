import { Routes, Route, Navigate } from "react-router-dom";
import { RequireAuth } from "Utils/RequireAuth";
import { CustomerRoutes } from "./CustomerRoutes";

export const AdminRoutes = () => {
  return (
    <RequireAuth>
      <Routes>
        <Route index element={<Navigate to="/admin/customers" />} />
        <Route path="customers/*" element={<CustomerRoutes />} />
      </Routes>
    </RequireAuth>
  );
};
