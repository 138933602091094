import { FC } from "react";

interface SelectProps {
  options: OptionProps[];
  label?: string;
  name: string;
  id: string;
  disabled?: boolean;
  selectClassNames?: string;
  defaultValue?: string;
  handleChange?: (val: string) => void;
}

interface OptionProps {
  label: string;
  value: string;
}

const Option: FC<OptionProps> = ({ label, value }) => (
  <option className="w-full" key={`${label}-${value}`} value={value}>
    {label}
  </option>
);

export const Select: FC<SelectProps> = ({
  name,
  label,
  id,
  options,
  selectClassNames,
  defaultValue,
  disabled = false,
  handleChange,
}) => {
  return (
    <div className="flex w-full flex-col">
      {label && (
        <label className="font-bold" htmlFor={id}>
          {label}
        </label>
      )}
      <select
        className={`mt-1 w-full rounded border-0 bg-gray-100 px-4 py-4 text-lg disabled:bg-gray-300 disabled:hover:cursor-not-allowed ${selectClassNames}`}
        name={name}
        id={id}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={(e) => {
          handleChange && handleChange(e.target.value);
        }}
      >
        {options.map(Option)}
      </select>
    </div>
  );
};
