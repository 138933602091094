import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppLayout } from "./Components";
import { FirebaseProvider } from "./Contexts/FirebaseContext";
import { CustomerProvider } from "./Contexts/CustomerContext";
import { LandingPage, Login } from "Pages";
import { AdminRoutes } from "Routes";

ReactDOM.render(
  <React.StrictMode>
    <FirebaseProvider>
      <CustomerProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<AppLayout />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="admin/*" element={<AdminRoutes />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </CustomerProvider>
    </FirebaseProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
