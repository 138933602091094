import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Content, CustomerCard, Input, Loading, PageTitle } from "Components";
import { useCustomers } from "Hooks";
import { Customer } from "Types";

export const CustomerList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState<Customer[]>();

  const { getAll } = useCustomers()!;

  const getCustomers = useCallback(
    async (query?: string) => {
      const data = await getAll(query);
      setCustomers(data);
      setLoading(false);
    },
    [getAll]
  );

  useEffect(() => {
    setLoading(true);
    getCustomers(search);
  }, [search, getCustomers]);

  useEffect(() => {
    setLoading(true);
    getCustomers();
  }, [getCustomers]);

  return (
    <>
      <PageTitle
        title="Customers"
        extraButtonText="Create Customer"
        handleExtraClick={() => navigate("/admin/customers/add")}
      />
      <Content>
        <div className="my-5 overflow-hidden rounded-lg bg-white pt-3 shadow-lg">
          <div className="border-b-2 px-3 pb-2">
            <Input
              handleChange={setSearch}
              id="userSearch"
              label="Search"
              placeholder="Jase Owens"
              inputType="text"
            ></Input>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="divide-y-2">
              {customers?.map((customer) => (
                <CustomerCard
                  key={`${customer?.name} + ${customer?.email}`}
                  data={customer}
                  handleClick={() => {
                    navigate(`/admin/customers/${customer.id}`);
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </Content>
    </>
  );
};
