import { Loading } from "Components";
import { useFirebase } from "Hooks";
import { FC } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuth: FC<any> = ({ children }) => {
  const { FIREBASE_AUTH } = useFirebase()!;
  const [user, loading] = useAuthState(FIREBASE_AUTH);
  let location = useLocation();

  if (loading) return <Loading />;

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
