import { ButtonProps } from "Components";
import { FC } from "react";

export const NatButton: FC<ButtonProps> = ({
  preIcon,
  postIcon,
  handleClick,
  type = "primary",
  size = "sm",
  wrapperClass,
  children,
}) => {
  const getColorClasses = () => {
    if (type === "primary")
      return "bg-secondary-500 hover:bg-secondary-600 text-white hover:text-white";
    if (type === "secondary")
      return "bg-gray-400 hover:bg-gray-300 text-white hover:text-white";
    if (type === "danger")
      return "bg-red-600 hover:bg-red-700 text-white hover:text-white";
    if (type === "link") return "text-secondary-500";
  };

  const getSizeClasses = () => {
    if (size === "sm") return "px-4 py-2";
    if (size === "md") return "px-5 py-3 text-lg";
    if (size === "lg") return "px-6 py-3 text-xl";
  };

  return (
    <button
      className={`rounded ${getColorClasses()} ${getSizeClasses()} ${wrapperClass}`}
      onClick={(e) => {
        e.preventDefault();
        handleClick && handleClick();
      }}
    >
      <span className="mr-2">{preIcon && preIcon}</span>
      {children}

      <span className="ml-2">{postIcon && postIcon}</span>
    </button>
  );
};
