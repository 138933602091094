import { useEffect, useState } from "react";
import {
  AboutUsFeature,
  EstimateDrawer,
  MainFeature,
  NatalieFeature,
  ServiceFeature,
  WhyFeature,
} from "./Components";
import PubSub from "pubsub-js";

export const LandingPage = () => {
  const [isShowEstimator, setShowEstimator] = useState(false);

  useEffect(() => {
    const handleEvent = () => {
      setShowEstimator(true);
    };
    const token = PubSub.subscribe("TOPNAV", handleEvent);

    return () => {
      PubSub.unsubscribe(token);
    };
  });

  return (
    <>
      <EstimateDrawer
        handleClose={() => setShowEstimator(false)}
        open={isShowEstimator}
      />
      <MainFeature handleShowEstimator={() => setShowEstimator(true)} />
      <WhyFeature />
      <ServiceFeature />
      <AboutUsFeature />
      <NatalieFeature />
    </>
  );
};
