import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  orderBy,
  query,
  startAt,
  updateDoc,
} from "firebase/firestore/lite";
import { createContext, FC } from "react";
import { useFirebase } from "Hooks";
import { Customer } from "Types";

type CustomerContextType = {
  getAll: (query?: string) => Promise<Customer[] | undefined>;
  get: (id: string) => Promise<Customer | undefined>;
  update: (id: string, data: Customer) => Promise<void>;
  create: (data: Customer) => Promise<string | null>;
  deleteCustomer: (id: string) => Promise<boolean>;
};

export const CustomerContext = createContext<CustomerContextType | undefined>(
  undefined
);

export const CustomerProvider: FC = ({ children }) => {
  const { FIREBASE_DB } = useFirebase()!;
  const CUSTOMER_COLLECTION = collection(FIREBASE_DB, "customers");

  const getAll = async (search?: string) => {
    if (search) {
      const q = query(
        collection(FIREBASE_DB, "customers"),
        orderBy("name"),
        startAt(search),
        endAt(search + "\uf8ff")
      );
      return await (
        await getDocs(q)
      ).docs.map((doc) => {
        return { ...doc.data(), id: doc.id } as Customer;
      });
    }
    return await (
      await getDocs(CUSTOMER_COLLECTION)
    ).docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as Customer;
    });
  };

  const get = async (id: string) => {
    const customerDoc = doc(FIREBASE_DB, "customers", id);

    const docData = await getDoc(customerDoc);

    if (docData.exists()) {
      return { ...docData.data(), id } as Customer;
    } else {
      return undefined;
    }
  };

  const update = async (id: string, data: Customer) => {
    const customerDoc = doc(FIREBASE_DB, "customers", id);
    return await updateDoc(customerDoc, { ...data });
  };

  const create = async (data: Customer) => {
    const add = await addDoc(CUSTOMER_COLLECTION, data);
    if (add) {
      return add.id;
    } else {
      return null;
    }
  };

  const deleteCustomer = async (id: string) => {
    const customerDoc = doc(FIREBASE_DB, "customers", id);
    await deleteDoc(customerDoc);
    return true;
  };

  return (
    <CustomerContext.Provider
      value={{ get, getAll, update, create, deleteCustomer }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
