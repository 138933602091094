import { FC } from "react";
import InputMask from "react-input-mask";

interface Props {
  id: string;
  label?: string;
  placeholder: string;
  defaultValue?: string;
  inputClassNames?: string;
  handleChange: (val: string) => void;
}

export const InputPhone: FC<Props> = ({
  id,
  label,
  defaultValue,
  inputClassNames,
  placeholder,
  handleChange,
}) => {
  return (
    <div className="w-full">
      {label && (
        <label className="text-sm font-bold" htmlFor={id}>
          {label}
        </label>
      )}
      <InputMask
        className={`mt-1 w-full rounded border-0 bg-gray-100 px-4 py-4 text-lg text-gray-900 placeholder-gray-400 focus:z-10 focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 ${inputClassNames}`}
        id={id}
        placeholder={placeholder}
        mask="(999) 999-9999"
        onChange={(e) => handleChange(e.target.value)}
        defaultValue={defaultValue}
      />
    </div>
  );
};
