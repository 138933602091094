import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, PageTitle, NatButton, Loading } from "Components";
import { ConfirmationModal } from "Components/Modals";
import { CustomerForm } from "Forms";
import { useCustomers } from "Hooks";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Customer } from "Types";
import { getPaymentIcon } from "Utils";

interface StatSectionProps {
  node: ReactNode;
  subTitle: string;
  handleClick?: () => void;
}

export const CustomerDetail = () => {
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const { get, update, deleteCustomer } = useCustomers()!;

  const [customer, setCustomer] = useState<Customer>();
  const [loading, setLoading] = useState(true);
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const getCustomer = useCallback(async () => {
    if (!params.id) return;
    const data = await get(params.id);
    setCustomer(data);
    setLoading(false);
  }, [get, params.id]);

  useEffect(() => {
    setLoading(true);
    getCustomer();
  }, [getCustomer]);

  const StatSection: FC<StatSectionProps> = ({
    node,
    subTitle,
    handleClick,
  }) => {
    return (
      <div>
        <div
          onClick={handleClick}
          className="flex aspect-square items-center justify-center rounded-lg bg-white text-5xl font-black text-gray-600 hover:scale-105 hover:cursor-pointer hover:shadow-lg hover:shadow-primary-900"
        >
          {node}
        </div>
        <div className="mt-5 text-center text-sm font-bold text-primary-900">
          {subTitle}
        </div>
      </div>
    );
  };

  const AddressIcon: ReactNode = (
    <FontAwesomeIcon className="text-amber-500" icon={faLocationDot} />
  );

  const handleSave = async () => {
    if (!customer || !params.id) return;
    delete customer.id;
    await update(params.id, customer);
    toast(`${customer?.name} was updated.`);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageTitle
            title={`${customer?.firstName} ${customer?.lastName}`}
            handlePreviousClick={() => navigate("/admin/customers")}
          />
          <Content>
            <div className="my-5 grid grid-cols-4 gap-8">
              <StatSection node="$100" subTitle="Revenue Generated" />
              <StatSection
                node={getPaymentIcon(customer?.paymentType)}
                subTitle="Prefered Payment"
              />
              <StatSection
                node={AddressIcon}
                subTitle="Address"
                handleClick={() => {
                  window.open(
                    `http://maps.google.com/maps?q=loc:${customer?.address}`,
                    "_blank"
                  );
                }}
              />
              <StatSection
                node={<div className="text-3xl">12/2/2022</div>}
                subTitle="Upcoming Cleaning"
              />
            </div>

            <CustomerForm handleChange={setCustomer} initialData={customer} />

            <div className="my-5 flex flex-row justify-between">
              <NatButton
                type="danger"
                handleClick={() => {
                  setConfirmDeleteOpen(true);
                }}
              >
                Delete
              </NatButton>
              <NatButton handleClick={handleSave}>Save</NatButton>
            </div>
          </Content>
        </>
      )}
      <ConfirmationModal
        body="Are you sure you want to delete this customer? This action can not be undone."
        title={`Delete ${customer?.name}?`}
        handleClose={async (accepted) => {
          setConfirmDeleteOpen(false);
          if (accepted) {
            if (!params.id) {
              toast(`Error occured trying to delete this customer`);
              return;
            }
            await deleteCustomer(params.id);
            toast(`${customer?.name} was deleted.`);
            navigate("/admin/customers");
          }
        }}
        isOpen={isConfirmDeleteOpen}
      />
    </>
  );
};
