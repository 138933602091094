import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { NatButton } from "Components";
import { FC, Fragment } from "react";

type Props = {
  isOpen: boolean;
  title: string;
  body: string;
  handleClose: (accepted: boolean) => void;
};

export const ConfirmationModal: FC<Props> = ({
  isOpen,
  title,
  body,
  handleClose,
}) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => handleClose(false)}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex min-h-screen items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <div className="relative mx-auto w-8/12 max-w-sm rounded bg-white">
              <div className="border-b-2">
                <Dialog.Title className="flex justify-between p-4 text-2xl font-black text-gray-700">
                  {title}
                  <FontAwesomeIcon
                    className="hover:cursor-pointer"
                    onClick={() => handleClose(false)}
                    icon={faTimes}
                  />
                </Dialog.Title>
              </div>

              <p className="text-md border-b-2 p-4">{body}</p>

              <div className="m-4 flex justify-between">
                <NatButton handleClick={() => handleClose(false)}>
                  Cancel
                </NatButton>
                <NatButton type="danger" handleClick={() => handleClose(true)}>
                  Delete
                </NatButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
