import { FC } from "react";
import { faAngleRight, faAt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Customer } from "Types";
import { CustomerStageTag } from "./CustomerStageTag";

export const CustomerCard: FC<{ data: Customer; handleClick: () => void }> = ({
  data,
  handleClick,
}) => {
  return (
    <div className="flex w-full flex-row items-center justify-between bg-white px-8 py-3 shadow hover:cursor-pointer hover:bg-gray-100">
      <div
        className="flex flex-row items-center"
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        {/* Avatar */}
        <div className="text-md mr-10 flex h-12 w-12 items-center justify-center rounded-full bg-primary-500 font-extrabold">
          {data.name?.split(" ").map((el) => el.charAt(0))}
        </div>
        {/* Name and Last Cleaning */}
        <div className="flex flex-col">
          <div className="flex text-xl font-bold text-gray-700">
            {data.name} <CustomerStageTag stage={data.stage} />
          </div>
          <div className="text-sm text-gray-500">
            {data.address}, {data.city}, {data.state} {data.zip}
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-4 text-xl text-secondary-300">
        {data.phoneNumber && (
          <a
            className="flex h-14 w-14 flex-col justify-center rounded px-2 py-2 text-primary-500 hover:bg-gray-200"
            href={`tel:+1${data.phoneNumber}`}
            rel="no-open"
          >
            <FontAwesomeIcon icon={faPhone} />
            <div className="pt-1 text-center text-xs tracking-wider">PHONE</div>
          </a>
        )}
        {data.email && (
          <a
            className="flex h-14 w-14 flex-col justify-center rounded px-2 py-2 text-primary-500 hover:bg-gray-200"
            href={`mailto:${data.email}`}
          >
            <FontAwesomeIcon icon={faAt} />
            <div className="pt-1 text-xs tracking-wider">EMAIL</div>
          </a>
        )}
        <button
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          className="h-14 w-14 rounded px-2 py-2 hover:bg-gray-200"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
};
