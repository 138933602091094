import { createContext, FC } from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore/lite";
import { FIREBASE_CONFIG } from "Config";

type FirebaseContextType = {
  FIREBASE_APP: FirebaseApp;
  FIREBASE_AUTH: Auth;
  FIREBASE_DB: Firestore;
};

export const FirebaseContext = createContext<FirebaseContextType | undefined>(
  undefined
);

export const FirebaseProvider: FC = ({ children }) => {
  const FIREBASE_APP = initializeApp(FIREBASE_CONFIG);
  const FIREBASE_AUTH = getAuth(FIREBASE_APP);
  const FIREBASE_DB = getFirestore(FIREBASE_APP);

  return (
    <FirebaseContext.Provider
      value={{ FIREBASE_APP, FIREBASE_AUTH, FIREBASE_DB }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
