import { Input, Select } from "Components";
import { InputPhone } from "Components/Data Input/InputPhone";
import { FC, useEffect, useState } from "react";
import { CustomerDetails } from "Types";

type CustomerDetailsFormProps = {
  wrapperClass?: string;
  initialData?: CustomerDetails;
  handleChange?: (data: CustomerDetails) => void;
};

export const CustomerDetailsForm: FC<CustomerDetailsFormProps> = ({
  wrapperClass,
  initialData,
  handleChange,
}) => {
  const [firstName, setFirstName] = useState(initialData?.firstName ?? "");
  const [lastName, setLastName] = useState(initialData?.lastName ?? "");
  const [email, setEmail] = useState(initialData?.email ?? "");
  const [phoneNumber, setPhoneNumber] = useState(
    initialData?.phoneNumber ?? ""
  );
  const [address, setAddress] = useState(initialData?.address ?? "");
  const [city, setCity] = useState(initialData?.city ?? "");
  const [state] = useState(initialData?.state ?? "AL");
  const [zip, setZip] = useState(initialData?.zip ?? "");

  useEffect(() => {
    const data: CustomerDetails = {
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      city,
      state,
      zip,
    };
    handleChange && handleChange(data);
  }, [
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    city,
    state,
    zip,
    handleChange,
  ]);

  return (
    <div className={wrapperClass}>
      <div className="flex flex-1 flex-row flex-nowrap space-x-3">
        <Input
          handleChange={setFirstName}
          label="First Name"
          id="firstName"
          inputType="text"
          placeholder="Jane"
          autoComplete="given-name"
          defaultValue={firstName}
        />

        <Input
          handleChange={setLastName}
          label="Last Name"
          id="lastName"
          inputType="text"
          placeholder="Smith"
          autoComplete="family-name"
          defaultValue={lastName}
        />
      </div>
      <div className="flex flex-1 flex-row flex-nowrap space-x-3">
        <Input
          handleChange={setEmail}
          label="Email"
          id="email"
          inputType="email"
          placeholder="janesmith@gmail.com"
          autoComplete="email"
          defaultValue={email}
        />
        <InputPhone
          handleChange={setPhoneNumber}
          label="Phone Number"
          id="phoneNumber"
          placeholder="(205) 123-4567"
          defaultValue={phoneNumber}
        />
      </div>
      <Input
        handleChange={setAddress}
        label="Street Address"
        id="address"
        inputType="text"
        placeholder="123 Apple Circle"
        autoComplete="street-address"
        defaultValue={address}
      />
      <div className="flex flex-1 flex-row flex-nowrap space-x-3">
        <Input
          handleChange={setCity}
          label="City"
          id="city"
          inputType="text"
          placeholder="Trussville"
          defaultValue={city}
        />

        <Select
          options={[{ label: "AL", value: "AL" }]}
          disabled
          label="State"
          id="state"
          name="state"
          defaultValue={state}
        />

        <Input
          handleChange={setZip}
          label="Zip"
          id="zip"
          inputType="text"
          placeholder="35173"
          defaultValue={zip}
        />
      </div>
    </div>
  );
};
