import {
  faQuestionCircle,
  faMoneyBill1Wave,
  faMoneyCheckAlt,
  faCreditCard,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { PaymentType } from "Types";

const UnknownIcon: ReactNode = (
  <FontAwesomeIcon className="text-red-400" icon={faQuestionCircle} />
);
const CashIcon: ReactNode = <FontAwesomeIcon icon={faMoneyBill1Wave} />;
const CheckIcon: ReactNode = <FontAwesomeIcon icon={faMoneyCheckAlt} />;
const CreditCardIcon: ReactNode = <FontAwesomeIcon icon={faCreditCard} />;
const MobileIcon: ReactNode = <FontAwesomeIcon icon={faMobile} />;

export const getPaymentIcon = (paymentType?: PaymentType) => {
  if (!paymentType || paymentType === PaymentType.UNKNOWN) return UnknownIcon;
  if (paymentType === PaymentType.CASH) return CashIcon;
  if (paymentType === PaymentType.CHECK) return CheckIcon;
  if (paymentType === PaymentType.CREDIT_CARD) return CreditCardIcon;
  if (paymentType === PaymentType.VENMO) return MobileIcon;
};
