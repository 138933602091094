import Logo from "Assets/NWNBroom.svg";
import {
  faFacebook,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";
import { faAt, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Footer = () => {
  return (
    <footer id="footer" className="border-t-2 bg-white p-6">
      <div className="flex justify-center space-x-2 text-2xl">
        <a href="https://www.facebook.com/NeatWithNat/">
          <FontAwesomeIcon className="text-secondary-500" icon={faFacebook} />
        </a>
        <a href="http://m.me/NeatWithNat">
          <FontAwesomeIcon
            className="text-secondary-500"
            icon={faFacebookMessenger}
          />
        </a>

        <a href="mailto:neatwithnat21@gmail.com">
          <FontAwesomeIcon className="text-secondary-500" icon={faAt} />
        </a>
      </div>
      <div className="flex justify-center p-6">
        <div className="flex flex-nowrap items-center">
          <div className="w-20">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="ml-3 flex flex-col justify-center text-2xl font-bold">
            <div className="text-center text-primary-500">NEAT</div>
            <div className="text-center text-lg text-primary-500">
              - <span className="text-secondary-500">WITH</span> -
            </div>
            <div className="text-center text-primary-500">NAT</div>
          </div>
        </div>
      </div>
      <div className="text-center">
        Made with <FontAwesomeIcon className="text-red-500" icon={faHeart} /> by{" "}
        <a href="https://yakapps.co/" target="_blank" rel="noreferrer">
          YakApps
        </a>
      </div>
      <div className="mt-2 text-center">Copyright &copy; 2022</div>
    </footer>
  );
};
