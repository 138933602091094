import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Content, PageTitle, NatButton } from "Components";
import { useCustomers } from "Hooks";
import { Customer } from "Types";
import { CustomerForm } from "Forms";
import { toast } from "react-toastify";

export const CreateCustomer = () => {
  const navigate = useNavigate();

  const { create } = useCustomers()!;

  const [customer, setCustomer] = useState<Customer>();

  const handleCreate = async () => {
    if (!customer) return;
    const res = await create(customer);
    if (res) {
      toast(`${customer?.name} was created.`);
      navigate("/admin/customers");
    } else {
      toast(`Error occured when creating ${customer?.name}.`);
    }
  };

  return (
    <>
      <PageTitle
        title="Create Customer"
        handlePreviousClick={() => navigate("/admin/customers")}
      />
      <Content innerClassNames="my-10">
        <CustomerForm handleChange={setCustomer} />
        <div className="mt-5 border-t-2 pt-3"></div>
        <div className="mt-5 flex flex-row justify-between">
          <NatButton
            type="secondary"
            handleClick={() => {
              navigate("/admin/customers");
            }}
          >
            Cancel
          </NatButton>
          <NatButton handleClick={handleCreate}>Create</NatButton>
        </div>
      </Content>
    </>
  );
};
